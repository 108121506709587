var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._l(_vm.suggetionDatas, function (data) {
    return _c('b-card', {
      key: data.type,
      attrs: {
        "no-body": ""
      }
    }, [_c('b-card-header', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle",
        value: data.type,
        expression: "data.type"
      }],
      attrs: {
        "header-tag": "header",
        "role": "tab"
      }
    }, [_vm._v(_vm._s(_vm.$t(data.type))), data.type !== 'evaluation.consultant_feedback' ? _c('span', {
      staticClass: "btn btn-primary ml-5"
    }, [_vm._v("Level " + _vm._s(data.level))]) : _vm._e()]), data.type === 'evaluation.consultant_feedback' ? _c('b-collapse', {
      attrs: {
        "id": data.type,
        "visible": "",
        "role": "tabpanel"
      }
    }, [_c('b-card-body', {
      staticClass: "blockquote"
    }, [_c('span', {
      domProps: {
        "innerHTML": _vm._s(data.content)
      }
    })])], 1) : _vm._e()], 1);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }