<template lang="pug">
  div
    b-card(v-for='data in suggetionDatas' :key='data.type' no-body='')
      b-card-header(v-b-toggle='data.type' header-tag='header' role='tab')
        | {{ $t(data.type) }}
        span.btn.btn-primary.ml-5(v-if="data.type !== 'evaluation.consultant_feedback'")
          | Level {{ data.level }}
      b-collapse(:id='data.type' visible='' role='tabpanel' v-if="data.type === 'evaluation.consultant_feedback'")
        b-card-body.blockquote
          span(v-html='data.content')
</template>

<script type="text/javascript">
import CannedResponses from '@/constants/cannedResponsesDictionary.json';

export default {
  name: 'ResultSuggestions',

  components: {},

  props: {
    // eslint-disable-next-line
    specificClassroomAnalysisData: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },

  data() {
    return {
      suggetionDatas: [
        {
          type: 'vocabulary',
          typeLevel: 'vocabulary_level',
          suggesionKey: 'suggestion_of_vocabulary',
          level: '',
          content: '',
        },
        {
          type: 'listening',
          typeLevel: 'listening_level',
          suggesionKey: 'suggestion_of_listening',
          level: '',
          content: '',
        },
        {
          type: 'grammar',
          typeLevel: 'grammar_level',
          suggesionKey: 'suggestion_of_grammar',
          level: '',
          content: '',
        },
        {
          type: 'speaking',
          typeLevel: 'speaking_level',
          suggesionKey: 'suggestion_of_speaking',
          level: '',
          content: '',
        },
        {
          type: 'reading',
          typeLevel: 'reading_level',
          suggesionKey: 'suggestion_of_reading',
          level: '',
          content: '',
        },
        {
          type: 'pronunciation',
          typeLevel: 'pronunciation_level',
          suggesionKey: 'suggestion_of_pronunciation',
          level: '',
          content: '',
        },
        {
          type: 'evaluation.consultant_feedback',
          typeLevel: '',
          suggesionKey: 'suggestion_of_consultant',
          level: '',
          content: '',
        },
      ],

      comment: CannedResponses,

      interestedId: '',
    };
  },

  computed: {},

  created() {},

  mounted() {
    this.getInterestedId();
    this.setSuggetionData();
  },

  methods: {
    setSuggetionData(){
      try {
        let i = 0;
        this.suggetionDatas.forEach(element => {
          this.suggetionDatas[i].level = this.specificClassroomAnalysisData[element.typeLevel];
          this.suggetionDatas[i].content = this.setSuggetionContent(element);
          i++;
        });
      } catch (e) {
        return false;
      }
    },

    setSuggetionContent(element){
      if (this.verifyKeyIsExist(this.specificClassroomAnalysisData[element.suggesionKey], element.type)){
        return this.comment.messages[this.interestedId][element.type].en[this.specificClassroomAnalysisData[element.suggesionKey]] + '<br><br>' + this.comment.messages[this.interestedId][element.type].tw[this.specificClassroomAnalysisData[element.suggesionKey]];
      } else {
        return this.specificClassroomAnalysisData[element.suggesionKey];
      }
    },

    getInterestedId() {
      // 需確認是否需要取得
      const dataArray = this.specificClassroomAnalysisData.material.interested_topics;
      try {
        for (let i = 0; i <= dataArray.length - 1; i++) {
          if (dataArray[i].id === 3 || dataArray[i].id === 12){
            this.interestedId = 'themeAndTravel';
            return;
          } else if (dataArray[i].id === 1){
            this.interestedId = 'business';
            return;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    verifyKeyIsExist(key, type) {
      try {
        const existEnKey = Object.prototype.hasOwnProperty.call(
          this.comment.messages[this.interestedId][type].en,
          key
        );
        const existTwKey = Object.prototype.hasOwnProperty.call(
          this.comment.messages[this.interestedId][type].tw,
          key
        );
        return existEnKey && existTwKey;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
