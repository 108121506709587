<script type="text/javascript">
import { Radar, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;
export default {
  name: 'RadarChart',

  extends: Radar,

  mixins: [reactiveProp],

  props: {
    options: {
      type: Object,
      default: function() {
        return { responsive: true, maintainAspectRatio: false };
      },
    },
  },

  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>
