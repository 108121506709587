<template lang="pug">
div
  .mb-5
    radar-chart(:chart-data="datacollection" :options="options")
  .wuwow-card
    .wuwow-card-head
      h4.wuwow-card-title.text-center {{ specificClassroomAnalysisData.material_title }}
    vip-classroom-info(:page="'viewAnalysis'")
    .wuwow-card-body
      .wuwow-accordion
        class-result-suggestions(:specificClassroomAnalysisData="specificClassroomAnalysisData")
    .wuwow-card-footer.text-center
      button.btn.btn-outline-primary(type='button' @click="() => {this.$router.go(-1);}")
        | {{ $t('router_back') }}
</template>

<script type="text/javascript">
import RadarChart from '@/components/chart/radarChart';
import VipClassroomInfo from '@/components/class/evaluations/vipClassroomInfo';
import ClassResultSuggestions from '@/components/class/evaluations/classResultSuggestions';
import { mapState } from 'vuex';

export default {
  name: 'VipViewAnalysis',

  components: {
    RadarChart,
    VipClassroomInfo,
    ClassResultSuggestions,
  },

  data() {
    return {

      typeList: [
        'vocabulary', 'grammar', 'listening', 'speaking', 'reading', 'pronunciation',
      ],

      datacollection: {},

      options: {
        responsive: true,
        maintainAspectRatio: false,
        scale: {
          ticks: {
            min: 0,
            max: 6, // JR最高等6
          },
          pointLabels: {
            fontSize: 16,
            fontFamily: 'Noto Sans TC,sans-serif',
          },
        },
        legend: {
          labels: {
            fontSize: 16,
            fontFamily: 'Noto Sans TC,sans-serif',
          },
        },
      },
    };
  },

  computed: {
    ...mapState({
      specificClassroomAnalysisData: (state) => state.classroom.specificClassroomAnalysisData,
    }),
  },

  created() {},

  mounted() {
    this.datacollection = {
      labels: this.setAnalysisChartTitle(),
      datasets: [
        {
          data: this.setAnalysisChartData(),
          label: this.$t('level'),
          backgroundColor: '#4085c0',
        },
      ],
    };
  },

  methods: {
    setAnalysisChartTitle(){
      try {
        const titleArray = [];
        Object.keys(this.typeList).forEach((key) => {
          titleArray.push(this.$t(this.typeList[key]));
        });
        return titleArray;
      } catch (err){
        console.log(err);
      }
    },

    setAnalysisChartData(){
      try {
        const dataArray = [];
        Object.keys(this.typeList).forEach((key) => {
          dataArray.push(this.specificClassroomAnalysisData[this.typeList[key] + '_level']);
        });
        return dataArray;
      } catch (err){
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.are-analysis-info {
  margin-top: 25px;
  font-size: 15px;
  text-align: center;
}
</style>
